<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import Cookies from 'js-cookie';

import { version } from '../package.json';

// import update from './mixins/update';
// import install from './mixins/install';

import PwaUpdateComponent from './components/PwaUpdateComponent.vue';
import HerbstBlaetter from './components/HerbstBlaetter.vue';
import SchneeflockenComponent from './components/SchneeflockenComponent.vue';

const temperature = ref(0);
const weather = ref({});
const weatherName = ref('');

const cookies = ref(undefined);
const store = useStore();

store.state.app.appVersion = version;

const {
  apiUrl, appUrl, community, appVersion,
} = store.state.app;
const appId = store.state.app.id;

const webShareApiSupported = computed({
  get: () => navigator.share,
});

function shareViaWebShare() {
  navigator.share({
    title: `Bürgerapp ${community}`,
    text: `Die Bürgerapp für ${community}`,
    url: appUrl,
  });
}

function setResults(results) {
  weather.value = results;
  temperature.value = results.temperature;
  weatherName.value = ''; // weather.value.weather[0].main;
}

function getWeather() {
  temperature.value = 0;
  fetch(`${apiUrl}/weather`, {
    method: 'GET',
    headers: {
      appid: appId,
      serviceid: 0,
      detailid: 0,
    },
  })
    // eslint-disable-next-line
    .then((res) => { return res.json(); })
    .then(setResults);
}

getWeather();

cookies.value = Cookies.get();
console.log('cookies:');
console.log(cookies.value);
if (cookies.value.loggedIn === 'true') {
  store.state.app.loggedIn = true;
  store.state.user.firstname = cookies.value.firstname;
  store.state.user.lastname = cookies.value.lastname;
  store.state.user.hash = cookies.value.hash;
  store.state.user.rights = cookies.value.rights;
}

// #f2b621 - dunkelorange
// #ffd416 - hellorange
// #eff823 - gelb
</script>

<template>
  <div class="flex justify-center">
    <!-- Hintergrundbild und Unschärfe über das Pseudo-Element -->
    <div class="fixed inset-0 bg-cover bg-center blur-sm opacity-50 z-0 bg-blurred"></div>
    <!-- header -->
    <div class="bg-gray-800 fixed pt-safe w-full max-w-screen-sm pl-safe pr-safe z-50">
      <div class="flex flex-row h-14 items-center">
        <router-link to="/">
          <img alt="Wappen" src="./assets/logo.png" class="h-10 m-1 flex-none">
        </router-link>
        <router-link to="/" class="grow">
          <h1 class="text-2xl text-red-600 grow font-light">{{ community }}</h1>
        </router-link>
        <div class="flex-none text-white">{{ weatherName }} {{ temperature }} °C</div>
        <a @click="$router.go(-1)"
          @keydown="1">
          <img alt="Menü" src="./assets/icon_arrow_5.png" class="h-8 m-2 flex-none">
        </a>
        <router-link to="/">
          <img alt="Menü" src="./assets/icon_common_5.png" class="h-10 m-2 flex-none">
        </router-link>
      </div>
    </div>
    <div class="max-w-screen-sm w-full bg-white z-20">
      <div class="flex flex-col min-h-screen w-full pt-14">
        <!-- content -->
        <div class="w-full pl-safe pr-safe flex-1">
          <PwaUpdateComponent />
          <div class="flex-auto w-full">
            <router-view class="bg-white"/>
          </div>
        </div>
        <!-- footer -->
        <div class="bg-gray-800 w-full pb-safe pt-1 text-white pl-safe pr-safe flex-none -z-0">
          <!-- HerbstBlaetter /-->
           <SchneeflockenComponent />
          <div class = "flex justify-center text-white">
            <router-link to="/impressum"
                class ="self-center">Impressum</router-link>
            <p class="w-4"></p>
            <router-link to="/dsgvo"
              class ="self-center">Datenschutzerklärung</router-link>
          </div>
            <div class = "flex justify-center text-white h-8">
              <!-- p class=""
                @click="$store.state.app.editmode = !$store.state.app.editmode"
                @keydown="1">
                Bearbeiten</p -->
              <p class="w-4"></p>
              <div class="self-center flex">
                <router-link to="/login"
                  class ="self-center flex">
                  <img
                    src="./assets/icon_app_1.png"
                    alt="share app"
                    class="w-6 h-6">
                  <div class="p-1"></div>
                  Login</router-link>
              </div>
              <p class="w-4"></p>
              <div class="self-center flex">
                <router-link to="/hilfe"
                  class ="self-center flex">
                  <img
                    src="./assets/icon_app_109.png"
                    alt="share app"
                    class="w-6 h-6">
                  <div class="p-1"></div>
                  Hilfe</router-link>
              </div>
              <p class="w-4"></p>
              <div v-if="webShareApiSupported"
                @click="shareViaWebShare"
                @keydown="1"
                class="self-center flex"
                >
                <img
                  src="./assets/icon_app_143.png"
                  alt="share app"
                  class="w-6 h-6">
                <div class="p-1"></div>
                App teilen</div>
              <a v-else :href="`https://api.whatsapp.com/send?text=${encodeURI(appUrl)}`"
                target="_blank"
                class="self-center">App teilen (WhatsApp)</a>
            </div>
            <div class = "flex justify-center text-white h-8">
              <p class="self-center">Bürgerapp {{ appVersion }}</p>
            <!-- div class="w-8 h-10"></div>
            <div style="border-radius: 30px 30px 30px 30px / 50px 50px 25px 25px"
              class="w-6 h-8 bg-red-500 absolute bottom-0 right-12"></div>
            <div style="border-radius: 30px 30px 30px 30px / 50px 50px 25px 25px"
              class="w-6 h-8 bg-lime-400 absolute bottom-0 right-4"></div>
            <div style="border-radius: 30px 30px 30px 30px / 50px 50px 25px 25px"
              class="w-6 h-8 bg-blue-400 absolute bottom-1 right-8"></div>
            <div style="border-radius: 50px 50px 50px 50px / 0px 0px 40px 40px"
              class="w-16 h-4 bg-yellow-200 absolute bottom-0 right-3"></div -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav a {
  font-weight: bold;
  color: #da121a;
}

h1 {
  color: #da121a;
}

.link {
  color: #da121a;
}
nav a.router-link-exact-active {
  color: #da121a;
}
.btn {
  @apply font-bold py-2 px-4 rounded;
}
.btn-red {
  @apply bg-red-500 text-white;
}
.btn-red:hover {
  @apply bg-red-700;
}

.btn-gray {
  @apply bg-gray-500 text-white;
}

.btn-light-red {
  @apply bg-transparent text-red-500 border border-red-500 px-4 py-2 rounded;
}
.btn-light-red-disabled {
  @apply bg-transparent text-gray-500 border border-gray-500 px-4 py-2 rounded;
}

.button-round {
  @apply border-gray-500 bg-lime-300 rounded-full shadow font-bold;
}

.bg-blurred::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./assets/titelbild.jpg');
  background-size: cover;
  background-position: center;
  filter: blur(8px);
  z-index: 0;
  opacity: 0.5;
}

</style>
